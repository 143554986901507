@media screen {
  .ov-shadow-sm {
    box-shadow: $box-shadow-sm !important;
  }
  .ov-shadow {
    box-shadow: $box-shadow !important;
  }
  .ov-shadow-lg {
    box-shadow: $box-shadow-lg !important;
  }
  .ov-shadow-none {
    box-shadow: none !important;
  }
}
