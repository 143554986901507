/* You can add global styles to this file, and also import other style files */

/* Button Styles */

$primary-color: yellow;
$danger-color: green !important;

// side menu active color
$info-color: purple;

.btn-dribble {
  background-color: #e49ef1 !important;
}

.btn-default {
  background-color: #b1b1b1 !important;
}

/* End of Button Styles */

.navbar.navbar-transparent {
  background-color: #a8adb1;
}
.main-content.overlap {
  border-top-color: #a8adb1;
}

.btn-primary {
  background-color: pink;
}
