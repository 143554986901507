.loader {
  z-index: 10000;
  border-radius: 50px;
  line-height: 100px;
  width: 100px;
  height: 100px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.4);
    border-radius: 50px;
    opacity: 0;
  }

  &:before {
    transform: scale(1);
    animation: pulse 2s infinite linear;
  }

  &:after {
    animation: pulse 2s 1s infinite linear;
  }
}

.loader-left {
  left: 25%;
}

.loader-right {
  right: 25%;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
